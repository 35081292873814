<template>
  <b-modal
    :id="modalId"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hide="checkPendingSave"
    @hidden="hidden"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ exploreOnly ? $t('explore') : $t('select2') }} {{ $t('audiences') }}</span>
      </h2>
      <p v-if="!isSaveScreenVisible">{{ exploreOnly ? $t('search_for_available_audiences') : $t('select_or_search_for_audiences') }}</p>
      <p v-else>{{ $t('save_selected_audiences') }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <!-- SAVE SCREEN -->
    <div class="text-center mt-3" v-if="isSaveScreenVisible">
      <p>{{ $t('save_changes_made_selected_audience_list') }}</p>
      <ul class="text-start ms-4">
        <li
          v-for="(audience, index) in sortedSelectedAudiences"
          :key="index"
        >
          {{ audience.name }}
        </li>
      </ul>
      <div class="mt-4 text-center">
        <Button
          type="button"
          class="btn-sm btn-color1 me-2"
          @click="forceCancel"
        >
          {{ $t('exit_without_saving') }}
        </Button>
        <Button
          type="button"
          class="btn-sm btn-primary"
          @click="submit"
        >
          {{ $t('save_audiences') }}
        </Button>
      </div>
    </div> <!-- END SAVE SCREEN -->
    <!-- AUDIENCES -->
    <div class="text-center mt-3" v-else>
      <!-- SELECTED AUDIENCES -->
      <div class="mb-4" v-if="selectedAudiences.length > 0 && !exploreOnly">
        <div class="text-start px-3">
          <h3 class="highlighted-color1 font-size-regular">
            <span>{{ $t('selected_audiences') }}</span>
          </h3>
          <!-- SELECTED AUDIENCE ITEM -->
          <div
            class="d-flex justify-content-between align-items-center mb-2"
            v-for="(audience, index) in sortedSelectedAudiences"
            :key="index"
          >
            <div>{{ audience.name }}</div>
            <div>
              <Button
                class="btn-xs btn-circle btn-danger font-size-small p-0"
                @click="removeAudience(audience.id)"
                v-b-tooltip.hover :title="$t('remove_audience')"
              >
                <i class="fa fa-trash"></i>
              </Button>
            </div>
          </div> <!-- END SELECTED AUDIENCE ITEM -->
          <div class="mt-4 text-center">
            <Button
              type="button"
              class="btn-sm btn-primary"
              @click="submit"
            >
              {{ $t('save_audiences') }}
            </Button>
          </div>
        </div>
      </div> <!-- END SELECTED AUDIENCES -->
      <!-- SEARCH -->
      <div class="mb-4">
        <form class="default-form" @submit.prevent="search">
          <div v-if="!filters.name">
            <p>{{ $t('enter_a_name_or_topic_to_search_for_an_audience') }}</p>
          </div>
          <input
            type="text"
            class="form-control form-control-sm"
            :placeholder="$t('search_for_an_audience')"
            v-model="filters.name"
            @keyup="search"
          />
        </form>
      </div><!-- END SEARCH -->
      <!-- CONTENT -->
      <div v-if="filters.name">
        <div v-if="audiences.length === 0 && loader">
          <Loader
            :size="'big'"
          />
        </div>
        <div v-else-if="audiences.length === 0 && filters.name && !this.loader">
          <p>{{ $t('no_audiences_found') }}</p>
        </div>
        <div v-else-if="audiences.length > 0">
          <div class="d-flex justify-content-around">
            <h3 class="highlighted-color1 font-size-regular">
              <span>{{ $t('available_audiences2') }}</span>
            </h3>
            <small v-if="filters.name && totalItems" class="text-muted">{{ $t('results_found') }}: {{ totalItems | formatNumber }}</small>
          </div>
          <!-- AUDIENCE ITEM -->
          <div
            class="audience-item d-flex justify-content-between align-items-center py-3 px-3"
            :class="{
              'selected': selectedAudiencesIds.includes(audience.id),
              'exploreOnly': exploreOnly
            }"
            v-for="(audience, index) in audiences"
            :key="index"
            @click="selectItem(audience)"
          >
            <div class="text-start font-size-small pe-3">{{ audience.name }}</div>
            <div v-if="!exploreOnly">
              <Button
                type="button"
                class="btn-xs"
                :class="{
                  'btn-primary': !selectedAudiencesIds.includes(audience.id),
                  'btn-color1': selectedAudiencesIds.includes(audience.id)
                }"
              >
                {{ selectedAudiencesIds.includes(audience.id) ? $t('selected') : $t('select2') }}
              </Button>
            </div>
          </div> <!-- END AUDIENCE ITEM -->
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              class="custom-pagination"
              v-if="totalPages > 1"
              v-model="page"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              :loading="loader"
              @input="getAudiences"
              hide-goto-end-buttons
            />
          </div>
        </div>
      </div> <!-- END CONTENT -->
    </div> <!-- END AUDIENCES -->
  </b-modal>
</template>

<script>

import AudiencesService from '@/modules/audiences/services/audiences-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'SelectAudienceModal',
  props: {
    categoryId: {
      type: String,
      default: ''
    },
    exploreOnly: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: function () {
        return []
      }
    },
    type: {
      type: String,
      default: null
    }
  },
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      audiences: [],
      content: null,
      content_type: 'error',
      filters: {
        name: ''
      },
      isPendingSave: false,
      isSaveScreenVisible: false,
      itemsPerPage: 0,
      loader: false,
      page: 1,
      selectedAudiences: [],
      totalItems: 0,
      totalPages: 0,
      throttleSearch: null
    }
  },
  computed: {
    /**
     * Modal ID
     */
    modalId () {
      if (this.exploreOnly) return 'explore-audience-modal'
      if (this.type === 'PLATFORM_DATA') return 'select-platform-audience-modal'
      return 'select-audience-modal'
    },
    /**
     * Selected audiences ids
     */
    selectedAudiencesIds () {
      return this.selectedAudiences.map(item => {
        return item.id
      })
    },
    /**
     * Sorted selected audiences by name
     */
    sortedSelectedAudiences () {
      const objs = Object.assign(this.selectedAudiences, {})

      return objs.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    /**
     * Check if there are pending audiences to be saved
     */
    checkPendingSave (e) {
      if (this.isPendingSave) {
        this.isSaveScreenVisible = true
        e.preventDefault()
      }
    },
    /**
     * Force cancel without saving
     */
    forceCancel () {
      this.isPendingSave = false
      this.$bvModal.hide(this.modalId)
    },
    /**
     * Get audiences
     */
    async getAudiences () {
      this.audiences = []
      this.loader = true
      try {
        const audiences = await AudiencesService.getAudiences({
          category_id: this.categoryId,
          name: this.filters.name,
          page: this.page,
          type: this.type
        })
        this.audiences = audiences.data
        this.totalItems = audiences.meta.total
        this.totalPages = audiences.meta.last_page
        this.itemsPerPage = audiences.meta.per_page
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Get audiences for search
     */
    getAudiencesForSearch () {
      this.audiences = []
      this.getAudiences()
    },
    /**
     * Hidden
     */
    hidden () {
      this.audiences = []
      this.selectedAudiences = []
      this.loader = false
      this.page = 1
      this.filters.name = ''
      this.content = null
      this.isPendingSave = false
      this.isSaveScreenVisible = false
    },
    /**
     * Remove audience
     */
    removeAudience (id) {
      const index = this.selectedAudiences.findIndex(item => {
        return item.id === id
      })
      this.selectedAudiences.splice(index, 1)
      this.isPendingSave = true
    },
    /**
     * Search audiences
     */
    search () {
      clearTimeout(this.throttleSearch)
      this.page = 1
      this.audiences = []
      this.throttleSearch = setTimeout(this.getAudiencesForSearch, 500)
    },
    /**
     * Select item
     */
    selectItem (audience) {
      if (this.exploreOnly) return
      if (this.selectedAudiencesIds.includes(audience.id)) return
      this.selectedAudiences.push(Object.assign({}, audience))
      this.isPendingSave = true
    },
    /**
     * Shown
     */
    shown () {
      if (this.audiences.length > 0) return
      if (this.selected.length > 0) {
        const audiences = [...this.selected]
        this.selectedAudiences = audiences.filter(item => {
          if (!this.type) return true
          return item.type === this.type
        })
      }
      // this.getAudiences()
    },
    /**
     * Submit
     */
    submit () {
      this.$emit('audiences-selected', this.selectedAudiences)
      this.isPendingSave = false
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.audience-item{
  &:hover {
    cursor: pointer;
    background: #eee;

    .select-button {
      display: block;
    }
  }

  &.exploreOnly {
    &:hover {
      cursor: auto;
    }
  }

  .select-button {
    display: none;
  }

  &.selected {
    background: #eee;
  }
}
</style>
