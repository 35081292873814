<template>
  <b-modal
    id="support-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h3 class="highlighted-color1">
        <span>{{ headerTitle }}</span>
      </h3>
      <p class="font-size-small mt-3 w-75 mx-auto">
        {{ $t('visit_our_blog') }}
        <br/>
        <a href="https://pixelroads.com.br/blog/" target="_blank" class="btn btn-sm btn-color1 py-2 mt-2">
          {{ $t('click_here') }}
        </a>
      </p>
      <p class="font-size-small">
        {{ $t('or') }}
      </p>
      <p class="font-size-small">
        {{ $t('talk_our_platform_support') }}
      </p>
    </div>
    <iframe
      src="https://typebot.co/suporte-pixel-roads"
      style="border: none; width: 100%; height: 430px"
      ></iframe>
  </b-modal>
</template>

<script>

export default {
  name: 'SupportModal',
  props: ['headerTitle']
}
</script>
